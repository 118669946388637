@media (min-width: 300px) and (max-width: 399.98px) { 
	.banner_main {
		height: 100vh;
		padding-top: 90px;
	}
	.banner_main .banner {
		height: 100vh;
		display: list-item;
		list-style: none;
	}
	.banner_main .banner .bannerText h3 {
		font-size: 24px;
		line-height: 34px;
	}
	.banner_main .banner .bannerText {
		width: 100%;
	}
	.banner_main .banner .bannerText p {
		font-size: 15px;
		line-height: 22px;
	}
	.banner_main .banner .bannerText .homeBtn {
		padding-top: 10px;
	}
	.banner_main .banner .bannerText .homeBtn a {
		padding: 10px 30px;
		font-size: 16px;
		border-radius: 35px;
	}
	.banner_main .banner .bannerImg {
		width: 100%;
		height: auto;
		margin-top: 40px;
	}
	.banner_main .client .clientMain {
		width: 90%;
	}
	.banner_main .client .clientMain .swiper .swiper-wrapper .swiper-slide img {
		width: 60px;
	}
	.banner_main .client .clientMain .swiper {
		padding: 12px 10px;
	}
	.sectionHeader h4 {
		font-size: 24px; 
		line-height: 34px;
		padding: 5px 0;
	}
	.feature_wrapper {
		padding: 20px 0;
	}
	.sectionHeader p {
		font-size: 15px;
		padding: 0 0px;
	}
	.feature_wrapper .feature_wrapper {
		width: 300px;
		margin: 10px auto;
	}
	.thumb_One .thumb_text h3 {
		font-size: 26px;
		line-height: 36px;
		padding: 8px 0 0 0;
	}
	.thumb_One .thumb_text h4 {
		font-size: 16px;
		line-height: 24px;
	}
	.thumb_One {
		height: 380px;
		margin-bottom: 50px;
	}
	.detail_body {
		padding: 10px;
	}
	.detail_body .detailBody_image {
		height: auto;
		width: auto;
	}
	.detail_body .detail_body_data {
		padding: 10px 10px 30px 10px;
		text-align: center;
	}
	.footer {
		padding: 30px 0 20px 0;
	}
	.footer .footer_wrapper .footer_side_one .footer_logo {
		width: 130px;
		padding-bottom: 28px;
	}
	.footer .footer_wrapper .footer_side_one .footer_icon {
		padding-top: 15px;
		padding-bottom: 10px;
	}
	.qrCode {
		height: 140px;
	}
	.footer .footer_wrapper .footer_side_two h4 {
		font-size: 20px;
		padding: 15px 0;
	}
	.contact_body {
		padding: 0px;
	}
	.contact_body .contact_container {
		width: 100%;
		padding: 15px;
	}
	.contact_right {
		padding: 10px;
	}
	.contact_body .contact_container .contact_left {
		display: none;
	}
 }
 @media (min-width: 400px) and (max-width: 575.98px) { 
	.banner_main {
		height: 90vh;
		padding-top: 90px;
	}
	.banner_main .banner {
		height: 90vh;
		display: list-item;
		list-style: none;
	}
	.banner_main .banner .bannerText h3 {
		font-size: 24px;
		line-height: 34px;
	}
	.banner_main .banner .bannerText {
		width: 100%;
	}
	.banner_main .banner .bannerText p {
		font-size: 15px;
		line-height: 22px;
	}
	.banner_main .banner .bannerText .homeBtn {
		padding-top: 10px;
	}
	.banner_main .banner .bannerText .homeBtn a {
		padding: 10px 30px;
		font-size: 16px;
		border-radius: 35px;
	}
	.banner_main .banner .bannerImg {
		width: 100%;
		height: auto;
		margin-top: 30px;
	}
	.banner_main .client .clientMain {
		width: 90%;
	}
	.banner_main .client .clientMain .swiper .swiper-wrapper .swiper-slide img {
		width: 60px;
	}
	.banner_main .client .clientMain .swiper {
		padding: 12px 10px;
	}
	.sectionHeader h4 {
		font-size: 24px; 
		line-height: 34px;
		padding: 5px 0;
	}
	.feature_wrapper {
		padding: 20px 0;
	}
	.sectionHeader p {
		font-size: 15px;
		padding: 0 0px;
	}
	.feature_wrapper .feature_wrapper {
		width: 300px;
		margin: 10px auto;
	}
	.thumb_One .thumb_text h3 {
		font-size: 26px;
		line-height: 36px;
		padding: 8px 0 0 0;
	}
	.thumb_One .thumb_text h4 {
		font-size: 16px;
		line-height: 24px;
	}
	.thumb_One {
		height: 380px;
		margin-bottom: 50px;
	}
	.detail_body {
		padding: 10px;
	}
	.detail_body .detailBody_image {
		height: auto;
		width: auto;
	}
	.detail_body .detail_body_data {
		padding: 10px 10px 30px 10px;
		text-align: center;
	}
	.footer {
		padding: 30px 0 20px 0;
	}
	.footer .footer_wrapper .footer_side_one .footer_logo {
		width: 130px;
		padding-bottom: 28px;
	}
	.footer .footer_wrapper .footer_side_one .footer_icon {
		padding-top: 15px;
		padding-bottom: 10px;
	}
	.qrCode {
		height: 140px;
	}
	.footer .footer_wrapper .footer_side_two h4 {
		font-size: 20px;
		padding: 15px 0;
	}
	.contact_body {
		padding: 0px;
	}
	.contact_body .contact_container {
		width: 100%;
		padding: 15px;
	}
	.contact_right {
		padding: 10px;
	}
	.contact_body .contact_container .contact_left {
		display: none;
	}
 }
 @media (min-width: 576px) and (max-width: 767.98px) { 
	.banner_main {
		height: 100vh;
		padding-top: 90px;
	}
	.banner_main .banner {
		height: 100vh;
		display: list-item;
		list-style: none;
	}
	.banner_main .banner .bannerText h3 {
		font-size: 24px;
		line-height: 34px;
	}
	.banner_main .banner .bannerText {
		width: 100%;
	}
	.banner_main .banner .bannerText p {
		font-size: 15px;
		line-height: 22px;
	}
	.banner_main .banner .bannerText .homeBtn {
		padding-top: 10px;
	}
	.banner_main .banner .bannerText .homeBtn a {
		padding: 10px 30px;
		font-size: 16px;
		border-radius: 35px;
	}
	.banner_main .banner .bannerImg {
		width: 100%;
		height: auto;
		margin-top: 30px;
	}
	.banner_main .client .clientMain {
		width: 80%;
	}
	.banner_main .client .clientMain .swiper .swiper-wrapper .swiper-slide img {
		width: 60px;
	}
	.banner_main .client .clientMain .swiper {
		padding: 12px 10px;
	}
	.sectionHeader h4 {
		font-size: 24px; 
		line-height: 34px;
		padding: 5px 0;
	}
	.feature_wrapper {
		padding: 20px 0;
	}
	.sectionHeader p {
		font-size: 15px;
		padding: 0 0px;
	}
	.feature_wrapper .feature_wrapper {
		width: 100%;
		margin: 10px auto;
	}
	.thumb_One .thumb_text h3 {
		font-size: 26px;
		line-height: 36px;
		padding: 8px 0 0 0;
	}
	.thumb_One .thumb_text h4 {
		font-size: 16px;
		line-height: 24px;
	}
	.thumb_One {
		height: 380px;
		margin-bottom: 50px;
	}
	.detail_body {
		padding: 10px;
	}
	.detail_body .detailBody_image {
		height: auto;
		width: auto;
	}
	.detail_body .detail_body_data {
		padding: 10px 10px 30px 10px;
		text-align: center;
	}
	.footer {
		padding: 30px 0 20px 0;
	}
	.footer .footer_wrapper .footer_side_one .footer_logo {
		width: 130px;
		padding-bottom: 28px;
	}
	.footer .footer_wrapper .footer_side_one .footer_icon {
		padding-top: 15px;
		padding-bottom: 10px;
	}
	.qrCode {
		height: 140px;
	}
	.footer .footer_wrapper .footer_side_two h4 {
		font-size: 20px;
		padding: 15px 0;
	}
	.contact_body {
		padding: 0px;
	}
	.contact_body .contact_container {
		width: 100%;
		padding: 15px;
	}
	.contact_right {
		padding: 10px;
	}
	.contact_body .contact_container .contact_left {
		display: none;
	}
	
 }
 @media (min-width: 768px) and (max-width: 991.98px) { 
	.banner_main {
		height: 100vh;
		padding-top: 90px;
	}
	.banner_main .banner {
		height: 100vh;
		display: list-item;
		list-style: none;
	}
	.banner_main .banner .bannerText h3 {
		font-size: 24px;
		line-height: 34px;
	}
	.banner_main .banner .bannerText {
		width: 100%;
	}
	.banner_main .banner .bannerText p {
		font-size: 15px;
		line-height: 22px;
	}
	.banner_main .banner .bannerText .homeBtn {
		padding-top: 10px;
	}
	.banner_main .banner .bannerText .homeBtn a {
		padding: 10px 30px;
		font-size: 16px;
		border-radius: 35px;
	}
	.banner_main .banner .bannerImg {
		width: 100%;
		height: auto;
		margin-top: 30px;
	}
	.banner_main .client .clientMain {
		width: 70%;
	}
	.banner_main .client .clientMain .swiper .swiper-wrapper .swiper-slide img {
		width: 60px;
	}
	.banner_main .client .clientMain .swiper {
		padding: 12px 10px;
	}
	.sectionHeader h4 {
		font-size: 24px; 
		line-height: 34px;
		padding: 5px 0;
	}
	.feature_wrapper {
		padding: 20px 0;
	}
	.sectionHeader p {
		font-size: 15px;
		padding: 0 0px;
	}
	.feature_wrapper .feature_wrapper {
		width: 100%;
		margin: 10px auto;
	}
	.thumb_One .thumb_text h3 {
		font-size: 26px;
		line-height: 36px;
		padding: 8px 0 0 0;
	}
	.thumb_One .thumb_text h4 {
		font-size: 16px;
		line-height: 24px;
	}
	.thumb_One {
		height: 380px;
		margin-bottom: 50px;
	}
	.detail_body {
		padding: 10px;
	}
	.detail_body .detailBody_image {
		height: auto;
		width: auto;
	}
	.detail_body .detail_body_data {
		padding: 10px 10px 30px 10px;
		text-align: center;
	}
	.footer {
		padding: 30px 0 20px 0;
	}
	.footer .footer_wrapper .footer_side_one .footer_logo {
		width: 130px;
		padding-bottom: 28px;
	}
	.footer .footer_wrapper .footer_side_one .footer_icon {
		padding-top: 15px;
		padding-bottom: 10px;
	}
	.qrCode {
		height: 140px;
	}
	.footer .footer_wrapper .footer_side_two h4 {
		font-size: 20px;
		padding: 15px 0;
	}
	.contact_body {
		padding: 0px;
	}
	.contact_body .contact_container {
		width: 100%;
		padding: 15px;
	}
	.contact_right {
		padding: 10px;
	}
	.contact_body .contact_container .contact_left {
		display: none;
	}
	.feature_wrapper .feature_wrapper .circle {
		height: 120px;
		width: 120px;
	}
	.feature_wrapper .feature_wrapper .circle img {
		max-width: 70px;
		max-height: 70px;
		margin: 32px auto;
		transition: all .3 s ease-in-out;
	}
 }
 
 @media (min-width: 992px) and (max-width: 1259.98px) {
	.banner_main .banner .bannerText h3 {
		font-size: 36px;
		line-height: 46px;
	}
	.banner_main .banner .bannerText p {
		font-size: 16px;
		line-height: 25px;
		padding-right: 20px;
	}
	.banner_main .banner .bannerText .homeBtn a {
		padding: 12px 35px;
		font-size: 16px;
	}
	.banner_main .client .clientMain {
		width: 80%;
	}
	.feature_wrapper .feature_wrapper .circle {
		height: 120px;
		width: 120px;
	}
	.feature_wrapper .feature_wrapper .circle img {
		max-width: 70px;
		max-height: 70px;
	}
	.feature_wrapper .feature_wrapper .feature_text h4 {
		font-size: 18px;
		line-height: 26px;
	}
	.thumb_One .thumb_text h3 {
		font-size: 40px;
		line-height: 50px;
		padding: 13px 0 0 0;
	}
	.thumb_One {
		height: 380px;
	}
	.qrCode {
		margin-top: 25px;
		height: 150px;
		width: auto;
	}
	.contact_body .contact_container {
		width: 100%;
	}
 }

@media (min-width:1260px){
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 1200px;
	}
}



